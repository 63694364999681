import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiUrl } from "../../componets/apiConfig";
import { useParams } from "react-router-dom";
import useFullPageLoader from "../../mui/useFullPageLoader";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { formatDate, formatInputDate } from "../../mui/dateFormate";
import Swal from "sweetalert2";
import AgreementPreview from "./AgreementPreview"
import { useUser } from "../../componets/UserContext";
import html2pdf from "html2pdf.js";


const CustomerAgreement = () => {
    const [userData, setuserData] = useState({});
    const navigate = useNavigate();
    const { setData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [errors, setErrors] = useState({});
    const [IsPreview, setIsPreview] = useState(false);
    const [Bank_DetailsFileName, setBank_DetailsFileName] = useState("Choose file");
    const [Payment_ProofFileName, setPayment_ProofFileName] = useState("Choose file");
    const [Aadhar_CardFileName, setAadhar_CardFileName] = useState("Choose file");
    const [Pan_CardFileName, setPan_CardFileName] = useState("Choose file");
    const [Attachment1FileName, setAttachment1FileName] = useState("Choose file");
    const [Attachment2FileName, setAttachment2FileName] = useState("Choose file");
    const { Id } = useParams();
    
    useEffect(() => {
        showLoader();        
    
        axios.get(`${apiUrl}/Customerdashboard`, { withCredentials: true })
            .then((res) => {
                if (res.data.Status === "Success") {
                    const userData = {
                        userId: res.data.UserId,
                        role: res.data.Role,
                        Name: res.data.Name,
                        Email: res.data.Email,
                        Org_Id: res.data.Org_Id,
                        Mobile: res.data.Mobile,
                    };
                    hideLoader();
                    setuserData(userData);
                    setData(userData);
                   // console.log(userData);
                } else {
                    console.error("Customer Auth Error:" + res);
                    navigate("/CustomerLogin");
                }
            });

    }, []);
    

    

    const [customer, setCustomer] = useState({
        AgDate: new Date(),
        Location: "",
        No_of_Lender: "1",
        Lender_PreName: "Mr.",
        Lender_Name: "",
        Lender1_PreName: "Mr.",
        Lender1_Name: "",
        Lender2_PreName: "Mr.",
        Lender2_Name: "",
        Lender_Address: "",
        Lender_Mobile_No: "",
        Lender_Registered_Mobile_No: "",
        Lender_Email_ID: "",
        Borrower_PreName: "Mr.",
        Borrower_Name: "",
        Borrower_Address: "",
        Borrower_Email_ID: "",
        Borrower_Mobile: "",
        Loan_Amount_txt: "",
        Loan_Amount_num: "",
        Loan_Start_Date: "",
        Loan_End_Date: "",
        Mode_of_Payment: "",
        Loan_Disbursal_Date: "",
        Transaction_ID: "",
        Bank: "",
        Total_Loan_Amount: "",
        Received_Date: "",
        Tenure: "Six months",
        KYC_Date: "",
        KYC_Time: "",

        LockIn_Period_From_Date: "",
        LockIn_Period_To_Date: "",
        PayIn_Bank_Account_Holder_PreName: "Mr.",
        PayIn_Bank_Account_Holder_Name: "",
        PayIn_Bank_Name: "",
        PayIn_Account_Number: "",
        PayIn_IFSC_Code: "",
        PayIn_Branch: "",
        PayIn_Account_Holder_Type: "",
        PayIn_Account_Type: "",

        Payout_Bank_Account_Holder_PreName: "Mr.",
        Payout_Bank_Account_Holder_Name: "",
        Payout_Bank_Name: "",
        Payout_Account_Number: "",
        Payout_IFSC_Code: "",
        Payout_Branch: "",
        Payout_Account_Holder_Type: "",
        Payout_Account_Type: "",

        Nominee1_PreName: "Mr.",
        Nominee1_Name: "",
        Nominee1_Address: "",
        Nomination_Gender: "his",
        Nominee_Loan_Amount: "",
        Nomination_Date: "",
        Nominee_DOB: "",
        Nominee_Relation: "",

        Declaration_Witness_PreName: "Mr.",
        Declaration_Witness_Name: "",
        Declaration_Witness_Address: "",
        Bank_Details: "",
        Payment_Proof: "",
        Aadhar_Card: "",
        Pan_Card: "",
        Attachment1: "",
        Attachment2: "",
    });


    const generateTaxInvoiceAndSavePDF = async (invoice) => {
        const element = document.getElementById("printablediv");
        const opt = {
            margin: 10,
            filename: "TaxInvoice.pdf",
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };

        try {
            const pdfBlob = await html2pdf().from(element).set(opt).outputPdf("blob");

            const whatsapp = {
                Customer: invoice?.Customer_Name,
                Certificate: invoice?.Certificate_Name,
                number: invoice?.Mobile,
                Email: invoice?.Email,
            };
            //console.log(whatsapp);
            showLoader();

            const formData = new FormData();
            formData.append("pdf", pdfBlob, "TaxInvoice.pdf");

            const response = await axios.post(`/saveAgreementDoc`, formData);
            hideLoader();


        } catch (error) {
            // Handle any errors
            console.error("Error saving PDF:", error);
            hideLoader();
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCustomer((prevCustomer) => ({
            ...prevCustomer,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        const formdata = new FormData();

        const updatedCustomer = {
            ...customer,
            User_Role: userData?.role,
            Added_By_Employee_Id: userData?.Employee_Id || "",
            Added_By_Id: userData.userId,
            Added_By: `${userData?.firstName} ${userData?.lastName}`,
            Org_Id: userData?.Org_Id,
            UserName: userData?.UserName,
        };

        // Append all customer data to formdata

        // Manually append each property from updatedCustomer
        formdata.append('AgDate', updatedCustomer.AgDate || "");
        formdata.append('Location', updatedCustomer.Location || "");
        formdata.append('No_of_Lender', updatedCustomer.No_of_Lender || "");
        formdata.append('Lender_PreName', updatedCustomer.Lender_PreName || "");
        formdata.append('Lender_Name', updatedCustomer.Lender_Name || "");
        formdata.append('Lender1_PreName', updatedCustomer.Lender1_PreName || "");
        formdata.append('Lender1_Name', updatedCustomer.Lender1_Name || "");
        formdata.append('Lender2_PreName', updatedCustomer.Lender2_PreName || "");
        formdata.append('Lender2_Name', updatedCustomer.Lender2_Name || "");
        formdata.append('Lender_Address', updatedCustomer.Lender_Address || "");
        formdata.append('Lender_Mobile_No', updatedCustomer.Lender_Mobile_No || "");
        formdata.append('Lender_Registered_Mobile_No', updatedCustomer.Lender_Registered_Mobile_No || "");
        formdata.append('Lender_Email_ID', updatedCustomer.Lender_Email_ID || "");
        formdata.append('Borrower_PreName', updatedCustomer.Borrower_PreName || "");
        formdata.append('Borrower_Name', updatedCustomer.Borrower_Name || "");
        formdata.append('Borrower_Address', updatedCustomer.Borrower_Address || "");
        formdata.append('Borrower_Email_ID', updatedCustomer.Borrower_Email_ID || "");
        formdata.append('Borrower_Mobile', updatedCustomer.Borrower_Mobile || "");
        formdata.append('Loan_Amount_txt', updatedCustomer.Loan_Amount_txt || "");
        formdata.append('Loan_Amount_num', updatedCustomer.Loan_Amount_num || "");
        formdata.append('Loan_Start_Date', updatedCustomer.Loan_Start_Date || "");
        formdata.append('Loan_End_Date', updatedCustomer.Loan_End_Date || "");
        formdata.append('Mode_of_Payment', updatedCustomer.Mode_of_Payment || "");
        formdata.append('Loan_Disbursal_Date', updatedCustomer.Loan_Disbursal_Date || "");
        formdata.append('Transaction_ID', updatedCustomer.Transaction_ID || "");
        formdata.append('Bank', updatedCustomer.Bank || "");
        formdata.append('Total_Loan_Amount', updatedCustomer.Total_Loan_Amount || "");
        formdata.append('Received_Date', updatedCustomer.Received_Date || "");
        formdata.append('Tenure', updatedCustomer.Tenure || "");
        formdata.append('KYC_Date', updatedCustomer.KYC_Date || "");
        formdata.append('KYC_Time', updatedCustomer.KYC_Time || "");
        formdata.append('LockIn_Period_From_Date', updatedCustomer.LockIn_Period_From_Date || "");
        formdata.append('LockIn_Period_To_Date', updatedCustomer.LockIn_Period_To_Date || "");
        formdata.append('PayIn_Bank_Account_Holder_PreName', updatedCustomer.PayIn_Bank_Account_Holder_PreName || "");
        formdata.append('PayIn_Bank_Account_Holder_Name', updatedCustomer.PayIn_Bank_Account_Holder_Name || "");
        formdata.append('PayIn_Bank_Name', updatedCustomer.PayIn_Bank_Name || "");
        formdata.append('PayIn_Account_Number', updatedCustomer.PayIn_Account_Number || "");
        formdata.append('PayIn_IFSC_Code', updatedCustomer.PayIn_IFSC_Code || "");
        formdata.append('PayIn_Branch', updatedCustomer.PayIn_Branch || "");
        formdata.append('PayIn_Account_Holder_Type', updatedCustomer.PayIn_Account_Holder_Type || "");
        formdata.append('PayIn_Account_Type', updatedCustomer.PayIn_Account_Type || "");
        formdata.append('Payout_Bank_Account_Holder_PreName', updatedCustomer.Payout_Bank_Account_Holder_PreName || "");
        formdata.append('Payout_Bank_Account_Holder_Name', updatedCustomer.Payout_Bank_Account_Holder_Name || "");
        formdata.append('Payout_Bank_Name', updatedCustomer.Payout_Bank_Name || "");
        formdata.append('Payout_Account_Number', updatedCustomer.Payout_Account_Number || "");
        formdata.append('Payout_IFSC_Code', updatedCustomer.Payout_IFSC_Code || "");
        formdata.append('Payout_Branch', updatedCustomer.Payout_Branch || "");
        formdata.append('Payout_Account_Holder_Type', updatedCustomer.Payout_Account_Holder_Type || "");
        formdata.append('Payout_Account_Type', updatedCustomer.Payout_Account_Type || "");
        formdata.append('Nominee1_PreName', updatedCustomer.Nominee1_PreName || "");
        formdata.append('Nominee1_Name', updatedCustomer.Nominee1_Name || "");
        formdata.append('Nominee1_Address', updatedCustomer.Nominee1_Address || "");
        formdata.append('Nomination_Gender', updatedCustomer.Nomination_Gender || "");
        formdata.append('Nominee_Loan_Amount', updatedCustomer.Nominee_Loan_Amount || "");
        formdata.append('Nomination_Date', updatedCustomer.Nomination_Date || "");
        formdata.append('Nominee_DOB', updatedCustomer.Nominee_DOB || "");
        formdata.append('Nominee_Relation', updatedCustomer.Nominee_Relation || "");
        formdata.append('Declaration_Witness_PreName', updatedCustomer.Declaration_Witness_PreName || "");
        formdata.append('Declaration_Witness_Name', updatedCustomer.Declaration_Witness_Name || "");
        formdata.append('Declaration_Witness_Address', updatedCustomer.Declaration_Witness_Address || "");
        formdata.append('Bank_Details', updatedCustomer?.Bank_Details || "");
        formdata.append('Payment_Proof', updatedCustomer?.Payment_Proof || "");
        formdata.append('Aadhar_Card', updatedCustomer?.Aadhar_Card || "");
        formdata.append('Pan_Card', updatedCustomer?.Pan_Card || "");
        formdata.append('Attachment1', updatedCustomer?.Attachment1 || "");
        formdata.append('Attachment2', updatedCustomer?.Attachment2 || "");

        // Append additional fields
        formdata.append('User_Role', updatedCustomer.User_Role || "");
        formdata.append('Added_By_Employee_Id', updatedCustomer.Added_By_Employee_Id || "");
        formdata.append('Added_By_Id', updatedCustomer.Added_By_Id || "");
        formdata.append('Added_By', updatedCustomer.Added_By || "");
        formdata.append('Org_Id', updatedCustomer.Org_Id || "");
        formdata.append('UserName', updatedCustomer.UserName || "");


        // Optional: Log formdata entries to inspect
        for (let pair of formdata.entries()) {
            console.log(`${pair[0]}: ${pair[1]}`);
        }

        const validationErrors = {};


        if (Object.keys(validationErrors).length === 0) {
            showLoader(); // Show loader before starting the request
            if (Id == null || Id === undefined) {
                try {
                    const response = await axios.post(`${apiUrl}/addLoanAgreement`, formdata);
                    // Handle successful response here
                    console.log(response.data);
                    Swal.fire({
                        title: "Success",
                        text: "Loan Agreement successfully added!",
                        icon: "success",
                        confirmButtonText: "OK",
                    });
                } catch (err) {
                    // Handle any errors here
                    console.error("Error while adding loan agreement:", err);
                    Swal.fire({
                        title: "Error",
                        text: "Failed to update. Please try again later.",
                        icon: "error",
                        confirmButtonText: "OK",
                    });
                } finally {
                    hideLoader();
                }
            }
            else {
                try {
                    const response = await axios.post(`${apiUrl}/updateLoanAgreement/${Id}`, formdata);
                    console.log(response.data);
                    Swal.fire({
                        title: "Success",
                        text: "Loan Agreement successfully added!",
                        icon: "success",
                        confirmButtonText: "OK",
                    });
                } catch (err) {
                    console.error("Error while adding loan agreement:", err);
                    Swal.fire({
                        title: "Error",
                        text: "Failed to update. Please try again later.",
                        icon: "error",
                        confirmButtonText: "OK",
                    });
                } finally {
                    hideLoader(); // Hide loader in both success and error cases
                }
            }
        } else {
            // Set validation errors to display them on the form
            setErrors(validationErrors);
        }
    };


    const showSummarry = async (event) => {
        event.preventDefault();
        const validationErrors = {};

        if (Object.keys(validationErrors).length === 0) {

            setIsPreview(true);
        } else {
            setErrors(validationErrors);
        }
    };

    const closed = (status) => {
        if (status) {
            handleSubmit();
        }
        setIsPreview(false);
    };
    return (
        <div className="container-fluid">
            <h5 className="page-title mt-4">Fresh Agreement</h5>
            <div className="card">

                <div className="card-body" style={{ paddingTop: "5px" }}>
                    <div>

                        <form onSubmit={showSummarry}>
                            <div className="row">
            
                                {/* Loan Amount */}
                                <h6 className="col-md-12 mt-3"><strong>Agreement Details: </strong></h6>
                                <div className="col-md-3 mb-3">
                                    <label>Date</label>
                                    <input
                                        type="date"
                                        name="AgDate"
                                        required
                                        value={formatInputDate(customer.AgDate)}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label>Loan Amount (Number)</label>
                                    <input
                                        type="text"
                                        name="Loan_Amount_num"
                                        required
                                        value={customer?.Loan_Amount_num}
                                        onChange={(e) => {
                                            const input = e.target.value;
                                            const decimalInput = input.replace(/[^0-9.]/g, "");
                                            const amount = decimalInput.replace(
                                                /^(\d*\.\d*)\..*$/,
                                                "$1"
                                            );
                                            setCustomer({
                                                ...customer,
                                                Loan_Amount_num: amount,
                                            });

                                        }}
                                        className="form-control"
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label>Loan Amount (Text)</label>
                                    <input
                                        type="text"
                                        required
                                        name="Loan_Amount_txt"
                                        value={customer.Loan_Amount_txt}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>


                                {/* Loan Dates */}
                                <div className="col-md-3 mb-3">
                                    <label>Loan Start Date</label>
                                    <input
                                        type="date"
                                        required
                                        name="Loan_Start_Date"
                                        value={formatInputDate(customer.Loan_Start_Date)}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label>Loan End Date</label>
                                    <input
                                        type="date"
                                        required
                                        name="Loan_End_Date"
                                        value={formatInputDate(customer.Loan_End_Date)}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>


                                {/* Mode of Payment */}
                                <div className="col-md-3 mb-3">
                                    <label>Mode of Payment</label>
                                    <input
                                        type="text"
                                        required
                                        name="Mode_of_Payment"
                                        value={customer.Mode_of_Payment}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label>Loan Disbursal Date</label>
                                    <input
                                        type="date"
                                        required
                                        name="Loan_Disbursal_Date"
                                        value={customer.Loan_Disbursal_Date}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                                {/* Transaction ID */}
                                <div className="col-md-3 mb-3">
                                    <label>Transaction ID</label>
                                    <input
                                        type="text"
                                        name="Transaction_ID"
                                        required
                                        value={customer.Transaction_ID}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                                {/* Bank */}
                                <div className="col-md-3 mb-3">
                                    <label>Bank</label>
                                    <input
                                        type="text"
                                        name="Bank"
                                        required
                                        value={customer.Bank}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label>Total Loan Amount</label>
                                    <input
                                        type="text"
                                        name="Total_Loan_Amount"
                                        required
                                        value={customer.Total_Loan_Amount}
                                        onChange={(e) => {
                                            const input = e.target.value;
                                            const decimalInput = input.replace(/[^0-9.]/g, "");
                                            const amount = decimalInput.replace(
                                                /^(\d*\.\d*)\..*$/,
                                                "$1"
                                            );
                                            setCustomer({
                                                ...customer,
                                                Total_Loan_Amount: amount,
                                            });

                                        }}
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label>Received Date</label>
                                    <input
                                        type="date"
                                        name="Received_Date"
                                        required
                                        value={customer.Received_Date}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>
                                {/* Tenure */}
                                <div className="col-md-3 mb-3">
                                    <label>Tenure</label>
                                    <select
                                        className="form-control"
                                        name="Tenure"
                                        required
                                        value={customer.Tenure}
                                        onChange={handleChange}

                                    >
                                        <option value="Six months">Six months</option>
                                        <option value="One Year">One Year</option>
                                    </select>
                                </div>

                                {/* KYC Date & Time */}
                                <div className="col-md-3 mb-3">
                                    <label>KYC Date</label>
                                    <input
                                        type="date"
                                        name="KYC_Date"
                                        required
                                        value={customer.KYC_Date}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label>KYC Time</label>
                                    <input
                                        type="time"
                                        name="KYC_Time"
                                        required
                                        value={customer.KYC_Time}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>
                                {/* Lock-in Period From Date */}
                                <div className="col-md-3 mb-3">
                                    <label>Lock-In Period From Date</label>
                                    <input
                                        type="date"
                                        name="LockIn_Period_From_Date"
                                        required
                                        value={customer.LockIn_Period_From_Date}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                                {/* Lock-in Period To Date */}
                                <div className="col-md-3 mb-3">
                                    <label>Lock-In Period To Date</label>
                                    <input
                                        type="date"
                                        name="LockIn_Period_To_Date"
                                        required
                                        value={customer.LockIn_Period_To_Date}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>
                                {/* Lender Details */}
                                <h6 className="col-md-12"><strong>{customer?.No_of_Lender === "1" ? 'Lender Details: ' : 'First Lender Details: '}</strong></h6>


                                <div className="col-md-2 mb-3">
                                    <label>No Of Lender</label>
                                    <select
                                        name="No_of_Lender"
                                        value={customer?.No_of_Lender}
                                        onChange={handleChange}
                                        className="form-control"
                                        required
                                    >
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>

                                    </select>
                                </div>

                                <div className="col-md-2 mb-3">
                                    <label>Lender Initial </label>
                                    <select
                                        name="Lender_PreName"
                                        value={customer.Lender_PreName}
                                        onChange={handleChange}
                                        className="form-control"
                                        required
                                    >
                                        <option value="Mr.">Mr.</option>
                                        <option value="Miss.">Miss.</option>
                                        <option value="Mrs.">Mrs.</option>
                                        <option value="Dr.">Dr.</option>
                                        {/* Add more options if needed */}
                                    </select>
                                </div>

                                <div className="col-md-8 mb-3">
                                    <label>Lender Name</label>
                                    <input
                                        type="text"
                                        name="Lender_Name"
                                        required
                                        value={customer.Lender_Name}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label>Lender Mobile Number</label>
                                    <input
                                        type="text"
                                        required
                                        name="Lender_Registered_Mobile_No" max={10}
                                        value={customer.Lender_Registered_Mobile_No}
                                        onChange={handleChange}
                                        className="form-control"

                                    />
                                </div>

                                <div className="col-md-4 mb-3">
                                    <label>Lender Alt Mobile Number</label>
                                    <input
                                        type="text"
                                        name="Lender_Mobile_No"
                                        required
                                        value={customer.Lender_Mobile_No}
                                        onChange={handleChange}
                                        className="form-control"

                                    />
                                </div>

                                <div className="col-md-4 mb-3">
                                    <label>Lender Email ID</label>
                                    <input
                                        type="email"
                                        name="Lender_Email_ID"
                                        required
                                        value={customer.Lender_Email_ID}
                                        onChange={handleChange}
                                        className="form-control"

                                    />
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label>Lender Address</label>
                                    <input
                                        name="Lender_Address"
                                        required
                                        value={customer.Lender_Address}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>
                                {(customer?.No_of_Lender === "2" || customer?.No_of_Lender === "3") && (
                                    <>
                                        <h6 className="col-md-12"><strong>Second Lender Details: </strong></h6>
                                        <div className="col-md-3 mb-3">
                                            <label>Lender Initial </label>
                                            <select
                                                name="Lender1_PreName"
                                                value={customer.Lender1_PreName}
                                                onChange={handleChange}
                                                className="form-control"
                                                required
                                            >
                                                <option value="Mr.">Mr.</option>
                                                <option value="Miss.">Miss.</option>
                                                <option value="Mrs.">Mrs.</option>
                                                <option value="Dr.">Dr.</option>
                                                {/* Add more options if needed */}
                                            </select>
                                        </div>

                                        <div className="col-md-9 mb-3">
                                            <label>Lender Name</label>
                                            <input
                                                type="text"
                                                name="Lender1_Name"
                                                required
                                                value={customer.Lender1_Name}
                                                onChange={handleChange}
                                                className="form-control"
                                            />
                                        </div></>
                                )}
                                {customer?.No_of_Lender === "3" && (
                                    <>
                                        <h6 className="col-md-12"><strong>Third Lender Details: </strong></h6>
                                        <div className="col-md-3 mb-3">
                                            <label>Lender Initial </label>
                                            <select
                                                name="Lender2_PreName"
                                                value={customer.Lender2_PreName}
                                                onChange={handleChange}
                                                className="form-control"
                                                required
                                            >
                                                <option value="Mr.">Mr.</option>
                                                <option value="Miss.">Miss.</option>
                                                <option value="Mrs.">Mrs.</option>
                                                <option value="Dr.">Dr.</option>
                                                {/* Add more options if needed */}
                                            </select>
                                        </div>

                                        <div className="col-md-9 mb-3">
                                            <label>Lender Name</label>
                                            <input
                                                type="text"
                                                name="Lender2_Name"
                                                required
                                                value={customer.Lender2_Name}
                                                onChange={handleChange}
                                                className="form-control"
                                            />
                                        </div></>
                                )}






                                <h6 className="col-md-12"><strong>Lender Bank Details: </strong></h6>

                                {/* PayIn Bank Account Holder Initial  */}
                                <div className="col-md-3 mb-3">
                                    <label>Account Holder Initial </label>
                                    <select
                                        name="PayIn_Bank_Account_Holder_PreName"
                                        value={customer.PayIn_Bank_Account_Holder_PreName}
                                        onChange={handleChange}
                                        required
                                        className="form-control"
                                    >
                                        <option value="Mr.">Mr.</option>
                                        <option value="Miss.">Miss.</option>
                                        <option value="Mrs.">Mrs.</option>
                                        <option value="Dr.">Dr.</option>
                                        {/* Add more options as needed */}
                                    </select>
                                </div>

                                {/* PayIn Bank Account Holder Name */}
                                <div className="col-md-3 mb-3">
                                    <label>Account Holder Name</label>
                                    <input
                                        type="text"
                                        name="PayIn_Bank_Account_Holder_Name"
                                        required
                                        value={customer.PayIn_Bank_Account_Holder_Name}
                                        onChange={handleChange}
                                        className="form-control"
                                    ///placeholder="Enter account holder name"
                                    />
                                </div>

                                {/* PayIn Bank Name */}
                                <div className="col-md-3 mb-3">
                                    <label>Bank Name</label>
                                    <input
                                        type="text"
                                        name="PayIn_Bank_Name"
                                        value={customer.PayIn_Bank_Name}
                                        onChange={handleChange} required
                                        className="form-control"
                                    // placeholder="Enter bank name"
                                    />
                                </div>

                                {/* PayIn Account Number */}
                                <div className="col-md-3 mb-3">
                                    <label>Account Number</label>
                                    <input
                                        type="text"
                                        name="PayIn_Account_Number"
                                        value={customer.PayIn_Account_Number}
                                        onChange={handleChange}
                                        required
                                        className="form-control"
                                    //placeholder="Enter account number"
                                    />
                                </div>

                                {/* PayIn IFSC Code */}
                                <div className="col-md-3 mb-3">
                                    <label>IFSC Code</label>
                                    <input
                                        type="text"
                                        name="PayIn_IFSC_Code"
                                        value={customer.PayIn_IFSC_Code}
                                        onChange={handleChange}
                                        required
                                        className="form-control"
                                    //placeholder="Enter IFSC code"
                                    />
                                </div>

                                {/* PayIn Branch */}
                                <div className="col-md-3 mb-3">
                                    <label>Branch</label>
                                    <input
                                        type="text"
                                        name="PayIn_Branch"
                                        value={customer.PayIn_Branch}
                                        onChange={handleChange}
                                        className="form-control"
                                        required
                                    //placeholder="Enter branch name"
                                    />
                                </div>

                                {/* PayIn Account Holder Type */}
                                <div className="col-md-3 mb-3">
                                    <label>Account Holder Type</label>
                                    <input
                                        type="text"
                                        name="PayIn_Account_Holder_Type"
                                        value={customer.PayIn_Account_Holder_Type}
                                        onChange={handleChange}
                                        required
                                        className="form-control"
                                    //placeholder="Enter account holder type"
                                    />
                                </div>

                                {/* PayIn Account Type */}
                                <div className="col-md-3 mb-3">
                                    <label>Account Type</label>
                                    <input
                                        type="text"
                                        name="PayIn_Account_Type"
                                        value={customer.PayIn_Account_Type}
                                        onChange={handleChange}
                                        className="form-control"
                                        required
                                    //placeholder="Enter account type"
                                    />
                                </div>

                                {/* <h6 className="col-md-12"><strong>Payout Details: </strong></h6>


                                <div className="col-md-3 mb-3">
                                    <label>Account Holder Initial </label>
                                    <select
                                        name="Payout_Bank_Account_Holder_PreName"
                                        value={customer.Payout_Bank_Account_Holder_PreName}
                                        onChange={handleChange}
                                        className="form-control"
                                        required
                                    >
                                        <option value="Mr.">Mr.</option>
                                        <option value="Miss.">Miss.</option>
                                        <option value="Mrs.">Mrs.</option>
                                        <option value="Dr.">Dr.</option>

                                    </select>
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label>Account Holder Name</label>
                                    <input
                                        type="text"
                                        name="Payout_Bank_Account_Holder_Name"
                                        required
                                        value={customer.Payout_Bank_Account_Holder_Name}
                                        onChange={handleChange}
                                        className="form-control"
                                    // placeholder="Enter account holder name"
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label>Bank Name</label>
                                    <input
                                        type="text"
                                        name="Payout_Bank_Name"
                                        required
                                        value={customer.Payout_Bank_Name}
                                        onChange={handleChange}
                                        className="form-control"
                                    //placeholder="Enter bank name"
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label>Account Number</label>
                                    <input
                                        type="text"
                                        name="Payout_Account_Number"
                                        required
                                        value={customer.Payout_Account_Number}
                                        onChange={handleChange}
                                        className="form-control"
                                    // placeholder="Enter account number"
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label>IFSC Code</label>
                                    <input
                                        type="text"
                                        name="Payout_IFSC_Code"
                                        value={customer.Payout_IFSC_Code}
                                        onChange={handleChange}
                                        required
                                        className="form-control"
                                    //placeholder="Enter IFSC code"
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label>Branch</label>
                                    <input
                                        type="text"
                                        name="Payout_Branch"
                                        value={customer.Payout_Branch}
                                        onChange={handleChange}
                                        required
                                        className="form-control"
                                    //placeholder="Enter branch name"
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label>Account Holder Type</label>
                                    <input
                                        type="text"
                                        name="Payout_Account_Holder_Type"
                                        value={customer.Payout_Account_Holder_Type}
                                        onChange={handleChange}
                                        required
                                        className="form-control"
                                    //placeholder="Enter account holder type"
                                    />
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label>Account Type</label>
                                    <input
                                        type="text"
                                        name="Payout_Account_Type"
                                        required
                                        value={customer.Payout_Account_Type}
                                        onChange={handleChange}
                                        className="form-control"
                                    //placeholder="Enter account type"
                                    />
                                </div>
 */}

                                <h6 className="col-md-12"><strong>Nominee Details: </strong></h6>

                                {/* Nominee Details */}
                                {/* Nominee 1 Initial  */}
                                <div className="col-md-3 mb-3">
                                    <label>Nominee Initial </label>
                                    <select
                                        name="Nominee1_PreName"
                                        required
                                        value={customer.Nominee1_PreName}
                                        onChange={handleChange}
                                        className="form-control"
                                    >
                                        <option value="Mr.">Mr.</option>
                                        <option value="Miss.">Miss.</option>
                                        <option value="Mrs.">Mrs.</option>
                                        <option value="Dr.">Dr.</option>

                                    </select>
                                </div>

                                {/* Nominee 1 Name */}
                                <div className="col-md-3 mb-3">
                                    <label>Nominee Name</label>
                                    <input
                                        type="text"
                                        name="Nominee1_Name"
                                        value={customer.Nominee1_Name}
                                        onChange={handleChange}
                                        required
                                        className="form-control"
                                    />
                                </div>

                                {/* Nominee 1 Address */}
                                <div className="col-md-3 mb-3">
                                    <label>Nominee Address</label>
                                    <input
                                        type="text"
                                        name="Nominee1_Address"
                                        required
                                        value={customer.Nominee1_Address}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                                {/* Nomination Gender */}
                                <div className="col-md-3 mb-3">
                                    <label>Nominee Gender</label>
                                    <select
                                        name="Nomination_Gender"
                                        value={customer.Nomination_Gender}
                                        onChange={handleChange}
                                        required
                                        className="form-control"
                                    >
                                        <option value="">Select Gender</option>
                                        <option value="his">Male</option>
                                        <option value="her">Female</option>
                                        {/* Add more options if needed */}
                                    </select>
                                </div>


                                {/* Nominee Loan Amount */}
                                <div className="col-md-3 mb-3">
                                    <label>Nominee Loan Amount</label>
                                    <input
                                        type="text"
                                        name="Nominee_Loan_Amount"
                                        required
                                        value={customer.Nominee_Loan_Amount}
                                        onChange={(e) => {
                                            const input = e.target.value;
                                            const decimalInput = input.replace(/[^0-9.]/g, "");
                                            const amount = decimalInput.replace(
                                                /^(\d*\.\d*)\..*$/,
                                                "$1"
                                            );
                                            setCustomer({
                                                ...customer,
                                                Nominee_Loan_Amount: amount,
                                            });

                                        }}
                                        className="form-control"
                                    />
                                </div>

                                {/* Nomination Date */}
                                <div className="col-md-3 mb-3">
                                    <label>Nomination Date</label>
                                    <input
                                        type="date"
                                        name="Nomination_Date"
                                        required
                                        value={formatInputDate(customer.Nomination_Date)}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                                {/* Nominee Date of Birth */}
                                <div className="col-md-3 mb-3">
                                    <label>Nominee DOB</label>
                                    <input
                                        type="date"
                                        name="Nominee_DOB"
                                        required
                                        value={formatInputDate(customer.Nominee_DOB)}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                                {/* Nominee Relation */}
                                <div className="col-md-3 mb-3">
                                    <label>Nominee Relation</label>
                                    <input
                                        type="text"
                                        name="Nominee_Relation"
                                        required
                                        value={customer.Nominee_Relation}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>


                                {/* Declaration */}

                                <h6 className="col-md-12"><strong>Witness Details: </strong></h6>

                                <div className="col-md-3 mb-3">
                                    <label>Witness Initial </label>
                                    <select
                                        name="Declaration_Witness_PreName"
                                        value={customer.Declaration_Witness_PreName}
                                        onChange={handleChange}
                                        required
                                        className="form-control"
                                    >
                                        <option value="Mr.">Mr.</option>
                                        <option value="Miss.">Miss.</option>
                                        <option value="Mrs.">Mrs.</option>
                                        <option value="Dr.">Dr.</option>
                                        {/* Add more options if needed */}
                                    </select>
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label>Witness Name</label>
                                    <input
                                        type="text"
                                        name="Declaration_Witness_Name"
                                        required
                                        value={customer.Declaration_Witness_Name}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label>Witness Address</label>
                                    <input
                                        type="text"
                                        name="Declaration_Witness_Address"
                                        required
                                        value={customer.Declaration_Witness_Address}
                                        onChange={handleChange}
                                        className="form-control"
                                    />
                                </div>
                                <h6 className="col-md-12"><strong>Documents: </strong></h6>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="Bank_Details">
                                            Bank Details
                                        </label>
                                        <div className="custom-file">
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id="Bank_Details"
                                                name="Bank_Details"
                                                accept=".jpg, .jpeg, .png, .pdf"
                                                onChange={(e) => {
                                                    const fileName =
                                                        e.target.files[0]?.name || "Choose file";
                                                    setBank_DetailsFileName(fileName);

                                                    setCustomer({
                                                        ...customer,
                                                        Bank_Details: e.target.files[0],
                                                    });
                                                }}
                                            />
                                            <label
                                                className="custom-file-label"
                                                htmlFor="Bank_Details"
                                                style={{ overflow: "hidden" }}
                                            >
                                                {Bank_DetailsFileName}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="Payment_Proof">
                                            Payment Proof
                                        </label>
                                        <div className="custom-file">
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id="Payment_Proof"
                                                name="Payment_Proof"
                                                accept=".jpg, .jpeg, .png, .pdf"
                                                onChange={(e) => {
                                                    const fileName =
                                                        e.target.files[0]?.name || "Choose file";
                                                    setPayment_ProofFileName(fileName);

                                                    setCustomer({
                                                        ...customer,
                                                        Payment_Proof: e.target.files[0],
                                                    });
                                                }}
                                            />
                                            <label
                                                className="custom-file-label"
                                                htmlFor="Payment_Proof"
                                                style={{ overflow: "hidden" }}
                                            >
                                                {Payment_ProofFileName}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="Aadhar_Card">
                                            Aadhar Card
                                        </label>
                                        <div className="custom-file">
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id="Aadhar_Card"
                                                name="Aadhar_Card"
                                                accept=".jpg, .jpeg, .png, .pdf"
                                                onChange={(e) => {
                                                    const fileName =
                                                        e.target.files[0]?.name || "Choose file";
                                                    setAadhar_CardFileName(fileName);

                                                    setCustomer({
                                                        ...customer,
                                                        Aadhar_Card: e.target.files[0],
                                                    });
                                                }}
                                            />
                                            <label
                                                className="custom-file-label"
                                                htmlFor="Aadhar_Card"
                                                style={{ overflow: "hidden" }}
                                            >
                                                {Aadhar_CardFileName}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="Pan_Card">
                                            Pan Card
                                        </label>
                                        <div className="custom-file">
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id="Pan_Card"
                                                name="Pan_Card"
                                                accept=".jpg, .jpeg, .png, .pdf"
                                                onChange={(e) => {
                                                    const fileName =
                                                        e.target.files[0]?.name || "Choose file";
                                                    setPan_CardFileName(fileName);

                                                    setCustomer({
                                                        ...customer,
                                                        Pan_Card: e.target.files[0],
                                                    });
                                                }}
                                            />
                                            <label
                                                className="custom-file-label"
                                                htmlFor="Pan_Card"
                                                style={{ overflow: "hidden" }}
                                            >
                                                {Pan_CardFileName}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="Attachment1">
                                            Attachment1
                                        </label>
                                        <div className="custom-file">
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id="Attachment1"
                                                name="Attachment1"
                                                accept=".jpg, .jpeg, .png, .pdf"
                                                onChange={(e) => {
                                                    const fileName =
                                                        e.target.files[0]?.name || "Choose file";
                                                    setAttachment1FileName(fileName);

                                                    setCustomer({
                                                        ...customer,
                                                        Attachment1: e.target.files[0],
                                                    });
                                                }}
                                            />
                                            <label
                                                className="custom-file-label"
                                                htmlFor="Attachment1"
                                                style={{ overflow: "hidden" }}
                                            >
                                                {Attachment1FileName}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="Attachment2">
                                            Attachment2
                                        </label>
                                        <div className="custom-file">
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id="Attachment2"
                                                name="Attachment2"
                                                accept=".jpg, .jpeg, .png, .pdf"
                                                onChange={(e) => {
                                                    const fileName =
                                                        e.target.files[0]?.name || "Choose file";
                                                    setAttachment2FileName(fileName);

                                                    setCustomer({
                                                        ...customer,
                                                        Attachment2: e.target.files[0],
                                                    });
                                                }}
                                            />
                                            <label
                                                className="custom-file-label"
                                                htmlFor="Attachment2"
                                                style={{ overflow: "hidden" }}
                                            >
                                                {Attachment2FileName}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12 text-center">
                                    <button className="btn btn-primary mt-2" type="submit">
                                        Submit
                                    </button>
                                </div>
                            </div>

                        </form>

                    </div>
                    {/* <Button
                        variant="contained"
                        color="primary"
                        startIcon={<PrintIcon />}
                        onClick={handleGeneratePDF}
                    >
                        Download PDF
                    </Button> */}
                    {loader}

                    {IsPreview && (
                        <AgreementPreview
                            open={IsPreview}
                            close={(status) => closed(status)}
                            customer={customer}
                            status={"0"}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default CustomerAgreement;
