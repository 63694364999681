import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link, Outlet, useNavigate } from "react-router-dom";
import ChangePasswordModal from "./ChangePasswordModal";
import { useUser } from "./UserContext";
import { apiUrl } from "./apiConfig";
import useFullPageLoader from "../mui/useFullPageLoader";
import CalculateIcon from '@mui/icons-material/Calculate';
import VerifiedIcon from "@mui/icons-material/Verified";
import $ from "jquery";
const Dashboard = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const allowedRoles = ["Admin", "DOP", "Checker", "Issuer"];
  const navigate = useNavigate();
  const { setData } = useUser();
  const [userData, setUserData] = useState(null);
  axios.defaults.withCredentials = true;
  axios.defaults.baseURL = `${apiUrl}/`;
  useEffect(() => {
    showLoader();
    axios.get(`${apiUrl}/dashboard`).then((res) => {
      if (res.data.Status === "Success") {
        const userData = {
          userId: res.data.UserId,
          role: res.data.Role,
          firstName: res.data.First_Name,
          lastName: res.data.Last_Name,
          email: res.data.Email,
          Org_Id: res.data.Org_Id,
          Employee_Id: res.data.Employee_Id,
          Mobile: res.data.Mobile,
          UserName: res.data.UserName
        };
        hideLoader();
        setUserData(userData);
        setData(userData);
        console.log(userData);
      } else {
        navigate("/login");
      }
    });
  }, []);

  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);

  const handleOpenChangePasswordModal = () => {
    setIsChangePasswordModalOpen(true);
  };

  const handleCloseChangePasswordModal = () => {
    setIsChangePasswordModalOpen(false);
  };

  const handleLogout = () => {
    axios
      .get(`${apiUrl}/logout`)
      .then((res) => {
        // Clear user data from state and navigate to the login page
        setUserData(null); // Assuming you have a state variable for userData
        navigate("/login");
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    const handleSidebarToggle = () => {
      $("body").toggleClass("sidebar-toggled");
      $(".sidebar").toggleClass("toggled");
    };
    $("#sidebarToggle, #sidebarToggleTop").on("click", handleSidebarToggle);
    return () => {
      $("#sidebarToggle, #sidebarToggleTop").off("click", handleSidebarToggle);
    };
  }, []);
  return (
    <div id="wrapper">
      <ul
        className="navbar-nav sidebar sidebar-light accordion"
        id="accordionSidebar"
      >
        <a
          className="sidebar-brand d-flex align-items-center justify-content-center"
          href="/"
          style={{
            backgroundColor: "#fff",
            boxShadow: "rgb(112 137 156) 0px 5px 21px -5px",
          }}
        >
          <div className="sidebar-brand-icon">
            <img src="/img/logo/logo.png" />
          </div>
          <div className="sidebar-brand-text" style={{ width: "180px" }}>
            <img src="/img/logo/logo2.png" />
          </div>
        </a>
        <hr className="sidebar-divider my-0" />
        <li className="nav-item active">
          <Link className="nav-link" to="/">
            <i className="fas fa-fw fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </Link>
        </li>
        <hr className="sidebar-divider" />
        <div className="sidebar-heading">Main</div>
        {(userData?.role === "Admin" || userData?.role === "Supervisor") && (
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#"
              data-toggle="collapse"
              data-target="#collapseBootstrap"
              aria-expanded="true"
              aria-controls="collapseBootstrap"
            >
              <i className="fas fa-fw fa-users-cog"></i>
              <span>Employees</span>
            </a>
            <div
              id="collapseBootstrap"
              className="collapse"
              aria-labelledby="headingBootstrap"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <Link className="collapse-item" to="addUser">
                  Add Employee
                </Link>
                <Link className="collapse-item" to="viewUser">
                  View Employee
                </Link>
              </div>
            </div>
          </li>
        )}

        {(userData?.role !== "DOP" && userData?.role !== "Checker" && userData?.role !== "Issuer") && (
   
        <li className="nav-item">
          <a
            className="nav-link collapsed"
            href="#"
            data-toggle="collapse"
            data-target="#collapseTable"
            aria-expanded="true"
            aria-controls="collapseTable"
          >
            <i className="fas fa-fw fa-regular fa-users"></i>
            <span>Customers</span>
          </a>
          <div
            id="collapseTable"
            className="collapse"
            aria-labelledby="headingTable"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <Link className="collapse-item" to="/addCustomer">
                Add Customer
              </Link>
              <Link className="collapse-item" to="/viewCustomer">
                View Customer
              </Link>
            </div>
          </div>
          </li>
        )}

        {(userData?.role !== "DOP" && userData?.role !== "Checker" && userData?.role !== "Issuer") && (
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#"
              data-toggle="collapse"
              data-target="#Verify"
              aria-expanded="true"
              aria-controls="Verify"
            >
              <i>
                <VerifiedIcon style={{ fontSize: "17px" }} />
              </i>
              <span>Pending Verification</span>
            </a>
            <div
              id="Verify"
              className="collapse"
              aria-labelledby="headingTable"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                {(userData?.role === "Admin" || userData?.role === "Supervisor") && (
                  <Link className="collapse-item" to="/verifyUser">
                    Verify User
                  </Link>
                )}
                <Link className="collapse-item" to="/verifyNewAccounts">
                  Account Opening
                </Link>
                <Link className="collapse-item" to="/verifyCloseAccounts">
                  Account Closure
                </Link>
                <Link className="collapse-item" to="/verifyTopup">
                  Verify Topup
                </Link>
                <Link className="collapse-item" to="/verifyUpgradation">
                  Verify Plan Upgradation
                </Link>
              </div>
            </div>
          </li>)}
        
        {(userData?.role !== "DOP" && userData?.role !== "Checker" && userData?.role !== "Issuer") && (
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#"
              data-toggle="collapse"
              data-target="#collapseTableMessage"
              aria-expanded="true"
              aria-controls="collapseTableMessage"
            >

              <i className="fab fa-fw fa-whatsapp-square"></i>
              <span>Whatsapp Message</span>
            </a>
            <div
              id="collapseTableMessage"
              className="collapse"
              aria-labelledby="headingTable"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">

                <Link className="collapse-item" to="/ManualWhatsapp">
                  Send Whatsapp Message
                </Link>
              </div>
            </div>
          </li>
        )}
       
    
        {allowedRoles.includes(userData?.role) && (
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#"
              data-toggle="collapse"
              data-target="#Agreement"
              aria-expanded="true"
              aria-controls="Agreement"
            >
              <i className="fas fa-file-contract"></i>
              <span>Agreement</span>
            </a>
            <div
              id="Agreement"
              className="collapse"
              aria-labelledby="headingTable"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <Link className="collapse-item" to="/SendAgreement">
                  Send Agreement
                </Link>
                <Link className="collapse-item" to="/Fresh_Agreement">
                  Fresh Agreement
                </Link>
                <Link className="collapse-item" to="/Renewal_Agreement">
                  Renewal Agreement
                </Link>
                <Link className="collapse-item" to="/ViewAgreement">
                  View Agreement
                </Link>
              </div>
            </div>
          </li>
        )}

        {(userData?.role !== "DOP" && userData?.role !== "Checker" && userData?.role !== "Issuer") && (
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#"
              data-toggle="collapse"
              data-target="#ReportForm"
              aria-expanded="true"
              aria-controls="ReportForm"
            >
              <i className="fa-fw fas fa-book-reader"></i>
              <span>Reports</span>
            </a>
            <div
              id="ReportForm"
              className="collapse"
              aria-labelledby="headingForm"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <Link className="collapse-item" to="/report">
                  Interest Reports
                </Link>
                <Link className="collapse-item" to={`/PayWithdrawalAmount/${new Date().toISOString()}`} style={{ overflow: "hidden", textWrap: "wrap" }}>
                  Withdrawal Payable Capital Amount
                </Link>
                <Link className="collapse-item" to="/viewPaidInterest">
                  Payment Statement
                </Link>
                <Link className="collapse-item" to="/activeAccount">
                  View Active Account
                </Link>
                <Link className="collapse-item" to="/closedAccount">
                  View Closed Account
                </Link>
                <Link className="collapse-item" to="/ledgerReport">
                  Customer Ledger Report
                </Link>
                {(userData?.role === "Admin" || userData?.role === "Supervisor") && (
                  <Link className="collapse-item" to="/activityLog">
                    Activity Log
                  </Link>)}
              </div>
            </div>
          </li>
        )}
        {(userData?.role !== "DOP" && userData?.role !== "Checker" && userData?.role !== "Issuer") && (
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="#"
              data-toggle="collapse"
              data-target="#collapseForm"
              aria-expanded="true"
              aria-controls="collapseForm"
            >
              <i className="fa-fw fas fa-cogs"></i>
              <span>Settings</span>
            </a>
            <div
              id="collapseForm"
              className="collapse"
              aria-labelledby="headingForm"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <Link className="collapse-item" to="/plan">
                  Plans
                </Link>

                <Link className="collapse-item" to="/bank">
                  Bank
                </Link>
                <Link className="collapse-item" to="/iDProofName">
                  ID Proof Name
                </Link>
                <Link className="collapse-item" to="/addressProofName">
                  Address Proof Name
                </Link>

                <Link className="collapse-item" to="/investmentPeriod">
                  Investment Period
                </Link>
                <Link className="collapse-item" to="/WhatsappTemplate">
                  Whatsapp Template
                </Link>
                {/*   <Link className="collapse-item" to="/percentageType">
              Percentage Type
            </Link>
              <Link className="collapse-item" to="/interestRate">
                Interest Rate
            </Link>*/}
              </div>
            </div>
          </li>
        )}

        {/*  <li className="nav-item">
          <a className="nav-link" href="ui-colors.html">
            <i className="fas fa-fw fa-palette"></i>
            <span>UI Colors</span>
          </a>
        </li>
        <hr className="sidebar-divider" />
        <div className="sidebar-heading">Examples</div>
        <li className="nav-item">
          <a
            className="nav-link collapsed"
            href="#"
            data-toggle="collapse"
            data-target="#collapsePage"
            aria-expanded="true"
            aria-controls="collapsePage"
          >
            <i className="fas fa-fw fa-columns"></i>
            <span>Pages</span>
          </a>
          <div
            id="collapsePage"
            className="collapse"
            aria-labelledby="headingPage"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Example Pages</h6>
              <a className="collapse-item" href="login.html">
                Login
              </a>
              <a className="collapse-item" href="register.html">
                Register
              </a>
              <a className="collapse-item" href="404.html">
                404 Page
              </a>
              <a className="collapse-item" href="blank.html">
                Blank Page
              </a>
            </div>
          </div>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="charts.html">
            <i className="fas fa-fw fa-chart-area"></i>
            <span>Charts</span>
          </a>
        </li>
        <hr className="sidebar-divider" />
        */}
      </ul>
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <nav
            className="navbar navbar-expand navbar-light bg-navbar topbar mb-4 static-top"
            style={{ backgroundColor: "rgb(55 84 108)" }}
          >
            <button
              id="sidebarToggleTop"
              className="btn btn-link rounded-circle mr-3"
            >
              <i className="fa fa-bars"></i>
            </button>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link" href="ms-calculator://">
                  <i><CalculateIcon /></i>
                </a>
              </li>



              <div className="topbar-divider d-none d-sm-block"></div>
              <li className="nav-item dropdown no-arrow">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="userDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    className="img-profile rounded-circle"
                    src="/img/boy.png"
                    style={{ maxWidth: "60px" }}
                  />
                  <span className="ml-2 d-none d-lg-inline text-white small">
                    {userData?.firstName} {userData?.lastName}
                  </span>
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                  aria-labelledby="userDropdown"
                >
                  <div className=" dropdown-header p-0 ml-3 noti-title">
                    <h6 className="text-overflow m-0">Welcome !</h6>
                  </div>
                  <div className="dropdown-divider"></div>
                  <Link
                    className="dropdown-item"
                    onClick={handleOpenChangePasswordModal}
                  >
                    <i className="fas fa-solid fa-key fa-sm fa-fw mr-2 text-gray-400"></i>
                    Change Password
                  </Link>

                  <Link className="dropdown-item" onClick={handleLogout}>
                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                    Logout
                  </Link>
                </div>
              </li>
            </ul>
          </nav>
          <Outlet />
        </div>
      </div>
      <ChangePasswordModal
        open={isChangePasswordModalOpen}
        onClose={handleCloseChangePasswordModal}
        UserId={userData?.userId}
        Role={userData?.role}
      />
      {loader}
    </div>
  );
};

export default Dashboard;
