import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import useFullPageLoader from "../../mui/useFullPageLoader";
import FileDownloadOffIcon from "@mui/icons-material/LinkOff";
import DownloadIcon from "@mui/icons-material/Visibility";
import dayjs from 'dayjs';
import { IconButton } from '@mui/material';
import { apiUrl } from "../../componets/apiConfig";
import { formatDate, formatInputDate } from "../../mui/dateFormate";
import html2pdf from "html2pdf.js";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useUser } from "../../componets/UserContext";
import Swal from 'sweetalert2';
import UpdateLoanAgreement from "./UpdateLoanAgreement"

function formatTime(timeString) {
    try {
        if (!timeString || timeString === 'null') return '';
        const [hours, minutes, seconds = '00'] = timeString.split(":").map(num => num.padStart(2, '0'));
        const date = new Date();
        date.setHours(parseInt(hours, 10));
        date.setMinutes(parseInt(minutes, 10));
        date.setSeconds(parseInt(seconds, 10));
        const formattedTime = date.toLocaleString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true }).toUpperCase();
        return formattedTime;
    } catch (error) {
        console.error("Error formatting time:", error);
        return "Invalid Time";
    }
}

const Verification = () => {
    const { userData } = useUser();
    const { id } = useParams();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [customer, setCustomer] = useState({});
    const [errors, setError] = useState({});
    const [images, setImage] = useState([]);
    const [IsPreview, setIsPreview] = useState(false);

    const showSummarry = async () => {
        setIsPreview(true);
    };

    const closed = () => {
        setIsPreview(false);
    };

    const [hr, setHr] = useState(true);

    const [selectedImage, setSelectedImage] = useState(null);



    const handleImageClick = (image) => {
        setSelectedImage(image);
    };



    useEffect(() => {
        loadCustomerData();
    }, []);

    const loadCustomerData = async () => {
        showLoader();
        try {
            const result = await axios.get(
                `${apiUrl}/getLoanAgreementById/${id}`
            );
            setCustomer(result.data);
            console.log(result?.data);
            setImage([
                `${apiUrl}/FreshAgreement/BankDetail/${result?.data?.Bank_Details}`,
                `${apiUrl}/FreshAgreement/PaymentProof/${result?.data?.Payment_Proof}`,
                `${apiUrl}/FreshAgreement/AadharCard/${result?.data?.Aadhar_Card}`,
                `${apiUrl}/FreshAgreement/PanCard/${result?.data?.Pan_Card}`,
                `${apiUrl}/FreshAgreement/Attachment1/${result?.data?.Attachment1}`,
                `${apiUrl}/FreshAgreement/Attachment2/${result?.data?.Attachment2}`
            ])

            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching data: ", error);
        }
    };

    const handleGeneratePDF = () => {
        const element = document.getElementById("printablediv");
        const options = {
            margin: [10, 15, 10, 15],
            filename: 'Fresh_Agreement.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            pagebreak: { mode: ['css', 'legacy'] }
        };

        html2pdf().from(element).set(options).toPdf().get('pdf').then((pdf) => {
            const totalPages = 7;
            while (pdf.internal.pages.length < totalPages) {
                pdf.addPage();
            }
        }).save();
    };

    const generateSavePDF = async () => {
        const element = document.getElementById("printablediv");
        const opt = {
            margin: [10, 15, 10, 15],
            filename: 'Fresh_Agreement.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            pagebreak: { mode: ['css', 'legacy'] }
        };

        try {
            const pdfBlob = await html2pdf().from(element).set(opt).outputPdf("blob");


            //console.log(whatsapp);
            showLoader();

            const formData = new FormData();
            formData.append("Agreement_Doc", pdfBlob, "Fresh_Agreement.pdf");

            await axios.put(`/saveAgreementDoc`, formData);
            hideLoader();


        } catch (error) {
            // Handle any errors
            console.error("Error saving PDF:", error);
            hideLoader();
        }
    };

    async function save(event) {
        event.preventDefault();

        const error = {};
        const updatedCustomer = {
            User_Role: userData?.role,
            Added_By_Employee_Id: userData?.Employee_Id ? userData?.Employee_Id : "",
            Added_By_Id: userData.userId,
            Added_By: `${userData?.firstName} ${userData?.lastName}`,
            Org_Id: userData?.Org_Id,
            UserName: userData?.UserName,
        };

        if (Object.keys(error).length === 0) {
            try {
                showLoader();
                await axios.post(`${apiUrl}/verifyLoanAgreement/${id}`, updatedCustomer);
                Swal.fire("Success!", "Verifid Loan Agreement successfully", "success");
                hideLoader();
            } catch (err) {
                Swal.fire({
                    title: "Error",
                    text: "Failed to Verify Agreement. Please try again later.",
                    icon: "error",
                    confirmButtonText: "OK",
                });
                hideLoader();
            }
        } else {
            setError(error);
        }
    }


    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column mb-2">
                        <h5 className="page-title">Agreement Verification</h5>
                    </div>
                    <div className="card mb-2">
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-sm-6 col-md-6 col-lg-6'>
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-12 col-lg-12'>
                                            <div className="row">
                                                {images.map((image, index) => {
                                                    // Assuming you have a way to get the document name (e.g., from the image URL)
                                                    const docName = image.split('/').pop(); // Extract the document name from the URL
                                                    const attachmentName = docName.split('_')[0];
                                                    return (
                                                        <div key={index} className="col-2">
                                                            <button
                                                                className="btn btn-sm"
                                                                style={{ backgroundColor: 'rgb(55, 84, 108)', color: 'white' }}
                                                                onClick={() => handleImageClick(image)}
                                                            >
                                                                {attachmentName || 'No Doc'}
                                                            </button>
                                                        </div>

                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className='col-sm-12 col-md-12 col-lg-12'>
                                            {selectedImage && (
                                                <div className="preview-panel">
                                                    <h6>Selected Document:</h6>
                                                    <div className="image-frame">
                                                        {selectedImage.endsWith('.pdf') ? (
                                                            <embed
                                                                src={selectedImage}
                                                                type="application/pdf"
                                                                width="100%"
                                                                height="600px"
                                                                onError={(e) => {
                                                                    e.target.src = ""; // Clear the src
                                                                    e.target.alt = "PDF not found"; // Set the alt text
                                                                }}
                                                            />
                                                        ) : (
                                                            <img
                                                                src={selectedImage}
                                                                alt="Selected"
                                                                className="img-fluid"
                                                                onError={(e) => {
                                                                    e.target.onerror = null; // Prevents infinite loop
                                                                    e.target.src = ""; // Clear the src
                                                                    e.target.alt = "Doc not found"; // Set the alt text
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>
                                <div className='col-sm-6 col-md-6 col-lg-6'>
                                    {customer ? (

                                        <div className="div" >
                                            {/* Content divided across pages */}
                                            <div className="row justify-content-center" id="printablediv"
                                                style={{
                                                    padding: "10px",
                                                    color: "#000",
                                                    maxHeight: "1250px",  // Set a max height
                                                    overflowY: "auto",   // Enable vertical scrolling
                                                    overflowX: "hidden"  // Disable horizontal scrolling if needed
                                                }}
                                            >
                                                {/* start page1 */}
                                                <div id="page1">
                                                    <h6 className="text-center"><u style={{ fontWeight: "bold" }}>Loan Agreement</u></h6>
                                                    <p style={{ padding: "7px" }}> This Agreement was made at Kalyan on {formatDate(customer?.Date)} between . Here in after referred to {customer?.Location} {customer?.Lender_PreName} {customer?.Lender_Name} residing at, {customer?.Lender_Address} as  <strong>'Lender'</strong> of the one part and  <strong>{customer?.Borrower_PreName} {customer?.Borrower_Name}</strong> residing at {customer?.Borrower_Address}. Hereinafter referred to as the  <strong>'Borrower'</strong> of the other part.</p>
                                                </div>
                                                {hr && <div style={{ borderTop: "5px solid black" }}></div>}

                                                {/* end page1 */}
                                                <div id="page2" style={{ marginTop: "69px" }}>

                                                    <p className="text-center" style={{ padding: "7px", marginBottom: 0 }}>
                                                        <strong>A loan Agreement between {customer?.Lender_PreName} {customer?.Lender_Name} & {customer?.Borrower_PreName} {customer?.Borrower_Name}.</strong><br />
                                                        ({customer?.Lender_PreName} {customer?.Lender_Name} is the  <strong>Lender</strong> and {customer?.Borrower_PreName} {customer?.Borrower_Name} is the  <strong>Borrower</strong>.)
                                                    </p>
                                                    <p style={{ padding: "7px", marginBottom: 0 }}>
                                                        <strong>1. Loan Amount and Duration:</strong> A loan amount of
                                                        <strong> {customer?.Loan_Amount_num}/- </strong>
                                                        <strong>({customer?.Loan_Amount_txt})</strong> (Principal Amount)
                                                        the amount has been transferred to the borrower,
                                                        <strong> {formatDate(customer?.Loan_Disbursal_Date)}</strong>. The period for which the loan is granted is one year,
                                                        from <strong> {formatDate(customer?.Loan_Start_Date)}</strong> to
                                                        <strong> {formatDate(customer?.Loan_End_Date)}</strong>.
                                                    </p>
                                                    <p style={{ padding: "7px", marginBottom: 0 }}><strong>Amount Received as follows:-</strong></p>
                                                    <div style={{ padding: "7px" }}>
                                                        <table border="1" cellPadding="2" cellSpacing="0">
                                                            <thead>
                                                                <tr>
                                                                    <th>LOAN AMOUNT</th>
                                                                    <th>MODE</th>
                                                                    <th>RECEIVED DATE</th>
                                                                    <th>START DATE</th>
                                                                    <th>TRANSACTION ID/ REF. NO</th>
                                                                    <th>BANK</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>{customer?.Loan_Amount_num}/-</td>
                                                                    <td>{customer?.Mode_of_Payment}</td>
                                                                    <td>{formatDate(customer?.Received_Date)}</td>
                                                                    <td>{formatDate(customer?.Loan_Start_Date)}</td>
                                                                    <td>{customer?.Transaction_ID}</td>
                                                                    <td>{customer?.Bank}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="6" className="text-center">
                                                                        <strong>
                                                                            TOTAL LOAN AMOUNT: RS.{customer?.Total_Loan_Amount}
                                                                        </strong>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                    </div>
                                                    <p style={{ padding: "7px", marginBottom: 0 }}>
                                                        <strong>Some conditions:</strong>
                                                    </p>

                                                    <p style={{ padding: "7px", marginBottom: 0 }}>
                                                        <strong>1. Lock-in-Period: </strong>
                                                        Lender need to keep loan amount with the borrower min for {customer?.Tenure},
                                                        withdrawing before {customer?.Tenure} is not allowed in any circumstances {formatDate(customer?.LockIn_Period_From_Date)} to {formatDate(customer?.LockIn_Period_From_Date)} .This
                                                        Lock-in-Period is applicable for lender not for borrower.
                                                    </p>

                                                    <div style={{ padding: "7px", marginBottom: 0 }}>
                                                        <strong>2. Withdrawal Clause: </strong>
                                                        <p style={{ padding: "7px", marginBottom: 0 }}>
                                                            A) This clause specifies that the loan is to be repaid by the borrower to the lenders at the end of this agreement period. The repayment can be a lump sum or on a periodical basis if the lender needs.
                                                        </p>
                                                        <p style={{ padding: "7px", marginBottom: 0 }}>
                                                            B) Whenever the lenders request withdrawal or repayment of the loan amount, from their registered Email ID {customer?.Lender_Email_ID} to our official Email ID {customer?.Borrower_Email_ID}, it shall be honored and paid in Part and/or Lump Sum by the Borrower as per the Slabwise withdrawal Payment Schedule from the date of the withdrawal/repayment request by lenders, without fail. During this Slabwise withdrawal Payment Schedule period, it is agreed by lenders that no Interest/Cost/Charges shall be paid by the Borrower for the period shown in the table below.
                                                        </p>
                                                    </div>

                                                    <p style={{ padding: "7px", marginBottom: 0 }}><strong>Slabwise Withdrawal Payment Schedule:</strong></p>
                                                    <div style={{ padding: "7px" }}>
                                                        <table style={{ width: "100%", borderCollapse: "collapse" }} border="1" cellPadding="2" cellSpacing="0">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ textAlign: "center" }}>RANGE AMOUNT ( IN RUPEES )</th>
                                                                    <th style={{ textAlign: "center" }}>NOTICE PERIOD DAYS</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ textAlign: "center" }}>1 LAKH TO 3 LAKH</td>
                                                                    <td style={{ textAlign: "center" }}>15 DAYS</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: "center" }}>4 LAKH TO 7 LAKH</td>
                                                                    <td style={{ textAlign: "center" }}>25 DAYS</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: "center" }}>8 LAKH TO 10 LAKH</td>
                                                                    <td style={{ textAlign: "center" }}>35 DAYS</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: "center" }}>11 LAKH TO 15 LAKH</td>
                                                                    <td style={{ textAlign: "center" }}>45 DAYS</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: "center" }}>16 LAKH TO 25 LAKH</td>
                                                                    <td style={{ textAlign: "center" }}>60 DAYS</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: "center" }}>ABOVE 26 LAKH</td>
                                                                    <td style={{ textAlign: "center" }}>72 DAYS</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                                <div id="page3" style={{ marginTop: "30px" }}>
                                                    <p style={{ padding: "7px", marginBottom: 0 }}>
                                                        C) Lenders will get Part Withdrawal or full withdrawal only after submitting Declaration
                                                        Part 1 & Declaration Part 2 including sign of witness & Nominee before 10 days of last date
                                                        of Slabwise withdrawal Payment Schedule.
                                                    </p>
                                                    <p style={{ padding: "7px", marginBottom: 0 }}>
                                                        D) We don’t do a withdrawal process in between 1st of every month to 12th of every month,
                                                        so whatever request we get from 1st to 12th of month we process after 15th of that
                                                        particular month.
                                                    </p>
                                                    <p style={{ padding: "7px", marginBottom: 0 }}>
                                                        E) If the lender is withdrawing the whole amount or part amount after 1.5 years including
                                                        the previous 1/ 2/ 3 agreements period. Then if we require time to investigate older
                                                        transactions to tally of loan amount and repaid amount whether it is Lump Sum or monthly
                                                        part payment, whatever days we require additionally, the borrower is not liable to pay
                                                        interest for those additional days to the lender.
                                                    </p>
                                                    <div style={{ padding: "7px", marginBottom: 0 }}>
                                                        <strong>3. Prepayment Clause: </strong>
                                                        <p style={{ padding: "7px", marginBottom: 0 }}>
                                                            Prepayment means early payment of loan, if the borrower wants to
                                                            do prepayment before this agreement end period, then borrower can do part prepayment
                                                            or entire prepayment, at any point of time before the end of {formatDate(customer?.Loan_End_Date)} with immediate
                                                            effect/ on the spot payment or with 15/30 days notice period as per borrower need or.
                                                        </p>

                                                        <p style={{ padding: "7px", marginBottom: 0 }}>
                                                            <strong>Note: A) Lender cannot force the borrower to continue this agreement if the borrower is
                                                                not willing to continue this agreement.</strong>

                                                        </p>
                                                        <p style={{ padding: "7px", marginBottom: 0 }}>
                                                            <strong>B) Borrower no need to give</strong> notice, intimation, call or message to the lender for immediate
                                                            <strong> prepayment of loan before agreement period and its lock-in-period.</strong>

                                                        </p>

                                                        <p style={{ padding: "7px", marginBottom: 0 }}>
                                                            <strong>4. Loan Security: </strong>This loan is without any collateral or asset like house or vehicle or
                                                            anything else, nothing is pledged as collateral for this loan and its loan agreement.
                                                        </p>


                                                        <p style={{ padding: "7px", marginBottom: 0 }}>
                                                            <strong>5. KYC Details: </strong>As per lenders given information, which is given on Date {formatDate(customer?.KYC_Date)}
                                                            Time {formatTime(customer?.KYC_Time)} on Our Mobile No. {customer?.Borrower_Mobile} from Lender’s Registered Mobile
                                                            No. {customer?.Lender_Registered_Mobile_No} Lender’s Email ID and Mobile No. are as follow:
                                                        </p>

                                                        <p style={{ padding: "7px", marginBottom: 0 }}>
                                                            <strong>Email ID : </strong>{customer?.Lender_Email_ID}
                                                        </p>

                                                        <p style={{ padding: "7px", marginBottom: 0 }}>
                                                            <strong>Mobile No : </strong>{customer?.Lender_Mobile_No}
                                                        </p>
                                                        <p style={{ padding: "7px", marginBottom: 0 }}>
                                                            In future Whatever communication will be through these email ID & Mobile Number only
                                                            and the lenders is agreed on this.

                                                        </p>
                                                        <div style={{ padding: "7px", marginBottom: 0 }}>
                                                            <h6><strong>6. Lenders Bank Account Details as follows: </strong></h6>
                                                            <p style={{ padding: "7px", marginBottom: 0 }}>
                                                                <strong>Note: </strong> Due to any issue, lenders Bank Account which was given to the borrower at the time
                                                                of agreement, if this lender's bank account got closed or changed or blocked or anything
                                                                wrong happen in lender's bank account then lender should inform to the borrower in written,
                                                                if lender is not informing to the borrower in written in e-mail on
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div id="page4" style={{ marginTop: "69px" }}>
                                                    <p style={{ padding: "7px", marginBottom: 0 }}>
                                                        {customer.Borrower_Email_ID} e-mail Id before 25th of particular month then Borrower is not
                                                        responsible If part or full loan payment done from borrower side to the lender's registered
                                                        bank account which was submitted by the lender at the time of agreement and if these
                                                        amount is not received to the lender then borrower is not liable for any kind of loss, this is
                                                        full responsibility of lender, in this case lender need to coordinate with their bank, borrower
                                                        will not coordinate with lender's bank, borrower will not come in-between lender's bank
                                                        and lender at any circumstances.

                                                    </p>
                                                    <p style={{ padding: "7px", marginBottom: 0 }}><strong>A) Lenders Bank Details For Pay-in:</strong></p>
                                                    <div style={{ padding: "7px" }}>
                                                        <table style={{ width: "100%", borderCollapse: "collapse" }} border="1" cellPadding="2" cellSpacing="0">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ textAlign: "left" }} colSpan={2}>Bank Account Details :</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ textAlign: "left", width: "177px" }}>Bank A/C Holder Name:</td>
                                                                    <td style={{ textAlign: "left" }}>{customer?.PayIn_Bank_Account_Holder_PreName} {customer?.PayIn_Bank_Account_Holder_Name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: "left", width: "177px" }}>Bank Name:</td>
                                                                    <td style={{ textAlign: "left" }}>{customer?.PayIn_Bank_Name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: "left", width: "177px" }}>Account No.:</td>
                                                                    <td style={{ textAlign: "left" }}>{customer?.PayIn_Account_Number}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: "left", width: "177px" }}>IFSC Code:</td>
                                                                    <td style={{ textAlign: "left" }}>{customer?.PayIn_IFSC_Code}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: "left", width: "177px" }}>Branch:</td>
                                                                    <td style={{ textAlign: "left" }}>{customer?.PayIn_Branch}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: "left", width: "177px" }}>Bank A/C Holder Type:</td>
                                                                    <td style={{ textAlign: "left" }}>{customer?.PayIn_Account_Holder_Type}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: "left", width: "177px" }}>Bank A/C Type:</td>
                                                                    <td style={{ textAlign: "left" }}>{customer?.PayIn_Account_Type}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <p style={{ padding: "7px", marginBottom: 0 }}><strong>B) Lenders Bank Details For Payout:</strong></p>
                                                    <div style={{ padding: "7px" }}>
                                                        <table style={{ width: "100%", borderCollapse: "collapse" }} border="1" cellPadding="2" cellSpacing="0">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ textAlign: "left" }} colSpan={2}>Bank Account Details :</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{ textAlign: "left", width: "177px" }}>Bank A/C Holder Name:</td>
                                                                    <td style={{ textAlign: "left" }}>{customer?.Payout_Bank_Account_Holder_PreName} {customer?.Payout_Bank_Account_Holder_Name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: "left", width: "177px" }}>Bank Name:</td>
                                                                    <td style={{ textAlign: "left" }}>{customer?.Payout_Bank_Name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: "left", width: "177px" }}>Account No.:</td>
                                                                    <td style={{ textAlign: "left" }}>{customer?.Payout_Account_Number}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: "left", width: "177px" }}>IFSC Code:</td>
                                                                    <td style={{ textAlign: "left" }}>{customer?.Payout_IFSC_Code}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: "left", width: "177px" }}>Branch:</td>
                                                                    <td style={{ textAlign: "left" }}>{customer?.Payout_Branch}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: "left", width: "177px" }}>Bank A/C Holder Type:</td>
                                                                    <td style={{ textAlign: "left" }}>{customer?.Payout_Account_Holder_Type}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: "left", width: "177px" }}>Bank A/C Type:</td>
                                                                    <td style={{ textAlign: "left" }}>{customer?.Payout_Account_Type}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <p style={{ padding: "7px", marginBottom: 0 }}>
                                                        <strong>7. Nominee Clause: </strong>{customer?.Lender_PreName} {customer?.Lender_Name} Here by Appointing {customer?.Nominee1_PreName} {customer?.Nominee1_Name} As a
                                                        Nominee In {customer?.Nomination_Gender} Loan Amount is Rs.{customer?.Nominee_Loan_Amount}/- on today {formatDate(customer?.Nomination_Date)}.


                                                    </p>
                                                    <p style={{ padding: "7px", marginBottom: 0 }}>
                                                        {customer?.Nominee1_PreName} {customer?.Nominee1_Name}

                                                    </p>
                                                    <p style={{ padding: "7px", marginBottom: 0 }}>
                                                        <strong>DOB: </strong>{formatDate(customer?.Nominee_DOB)}
                                                    </p>
                                                    <p style={{ padding: "7px", marginBottom: 0 }}>
                                                        <strong>Relation: </strong>{customer?.Nominee_Relation}

                                                    </p>
                                                </div>
                                                <div id="page5" style={{ marginTop: "72px" }}>
                                                    <p style={{ padding: "7px", marginBottom: 0 }}>
                                                        <strong>A) </strong>After the lender's Death nominee needs to claim this loan amount, this is the nominee's
                                                        responsibility.


                                                    </p>
                                                    <p style={{ padding: "7px", marginBottom: 0 }}>
                                                        <strong>A) </strong>After the lender's Death nominee needs to claim this loan amount, this is the nominee's
                                                        responsibility.


                                                    </p>
                                                    <p style={{ padding: "7px", marginBottom: 0 }}>
                                                        <strong>B) </strong>Due to any reason if the claim gets delayed then no any interest will be applicable on loan
                                                        amount as well as pending interest amount.
                                                    </p>
                                                    <p style={{ padding: "7px", marginBottom: 0 }}>
                                                        <strong>C) </strong>Due to any reason, if the nominee claims the lender’s loan amount to the borrower after
                                                        so many days or months or years then it's not the borrower's fault, so the borrower is not
                                                        liable to pay any interest on that period.

                                                    </p>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <p style={{ padding: "7px", marginBottom: 0 }}>
                                                                {customer?.Nominee1_PreName} {customer?.Nominee1_Name}
                                                            </p>
                                                            <p style={{ padding: "7px", marginTop: '88px' }}>
                                                                Signature
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 text-md-end">
                                                            <p style={{ padding: "7px", marginBottom: 0 }}>
                                                                {customer?.Lender_PreName} {customer?.Lender_Name}
                                                            </p>
                                                            <p style={{ padding: "7px", marginTop: '88px' }}>
                                                                Signature
                                                            </p>
                                                        </div>


                                                    </div>
                                                    <p style={{ padding: "7px", marginBottom: 0 }}>
                                                        <strong>8. </strong>All Previous Agreements issued on same fund’s Transaction IDs will be considered as
                                                        Laps/Barred/ cancelled and only this agreement will be valid for all mentioned transaction
                                                        IDs. We are liable to refund only the amounts which are mentioned in this agreement.


                                                    </p>
                                                    <p style={{ padding: "7px", marginBottom: 0 }}>
                                                        <strong>9. </strong>This Agreement is strictly not used for submitting with ITR file, to show this to CA or not to
                                                        use as a Income proof for any purpose, this Agreement is only a Agreement which is made
                                                        for two parties lender & borrower to obey all the terms and conditions and it should be kept
                                                        separate from any other use.


                                                    </p>
                                                    <p style={{ padding: "7px", marginBottom: 0 }}>
                                                        <strong>10. </strong>This is very clear that any communication regarding this loan agreement will be (in
                                                        between lender & borrower) only neither with lender’s nominee nor witness is authorised
                                                        to contact to lender’s or to email to the borrower or whatsapp message to borrower
                                                        This loan agreement is signed by both the parties in order to avoid any dispute that may
                                                        occur later


                                                    </p>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <p style={{ padding: "7px", marginBottom: 0 }}>
                                                                Lender sign & Thumb Impression
                                                            </p>

                                                        </div>
                                                        <div className="col-md-6 text-end">
                                                            <p style={{ padding: "7px", marginBottom: 3 }}>
                                                                Borrower sign & Thumb Impression

                                                            </p>

                                                        </div>

                                                    </div>
                                                    <div className="row" style={{ marginTop: '88px' }}>
                                                        <div className="col-md-6">
                                                            <p style={{ padding: "7px", marginBottom: 0 }}>
                                                                {customer?.Lender_PreName} {customer?.Lender_Name}
                                                            </p>
                                                            <p style={{ padding: "7px", marginBottom: 0, marginTop: 0 }}>
                                                                (Date: &nbsp;&nbsp;&nbsp;&nbsp;/ &nbsp;&nbsp;&nbsp;&nbsp;/  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6 text-md-end">
                                                            <p style={{ padding: "7px", marginBottom: 0 }}>
                                                                {customer?.Borrower_PreName} {customer?.Borrower_Name}
                                                            </p>
                                                            <p style={{ padding: "7px", marginBottom: 0, marginTop: 0 }}>
                                                                (Date: &nbsp;&nbsp;&nbsp;&nbsp;/ &nbsp;&nbsp;&nbsp;&nbsp;/  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)

                                                            </p>
                                                        </div>


                                                    </div>
                                                </div>

                                                <div id="page6" style={{ marginTop: "107px" }}>

                                                    <div className="row">
                                                        {/* Borrower Details */}
                                                        <div className="col-md-8">
                                                            <p style={{ padding: "7px", marginBottom: 0 }}><strong>Borrower Name & Address:</strong><br />
                                                                <strong>Name: </strong> {customer?.Borrower_Name}<br />

                                                                <strong>Address: </strong>{customer?.Borrower_Address}
                                                            </p>

                                                        </div>

                                                        {/* Signature Section */}
                                                        <div className="col-md-4 d-flex flex-column align-items-center">
                                                            <div className="border" style={{ width: '100px', height: '120px' }}></div>
                                                            <p className="mt-2 mb-4 fw-bold">Signature</p>
                                                            <p className="mb-2">(Date: &nbsp;&nbsp;&nbsp;&nbsp;/ &nbsp;&nbsp;&nbsp;&nbsp;/  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</p>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        {/* Borrower Details */}
                                                        <div className="col-md-8">
                                                            <p style={{ padding: "7px", marginBottom: 0 }}><strong>Lender Name & Address:</strong><br />
                                                                <strong>Name: </strong> {customer?.Lender_Name}<br />

                                                                <strong>Address: </strong>{customer?.Lender_Address}
                                                            </p>

                                                        </div>

                                                        {/* Signature Section */}
                                                        <div className="col-md-4 d-flex flex-column align-items-center">
                                                            <div className="border" style={{ width: '100px', height: '120px' }}></div>
                                                            <p className="mt-2 mb-4 fw-bold">Signature</p>
                                                            <p className="mb-2">(Date: &nbsp;&nbsp;&nbsp;&nbsp;/ &nbsp;&nbsp;&nbsp;&nbsp;/  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</p>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        {/* Borrower Details */}
                                                        <div className="col-md-8">
                                                            <p style={{ padding: "7px", marginBottom: 0 }}><strong>Nominee Name & Address:</strong><br />
                                                                <strong>Name: </strong> {customer?.Nominee1_Name}<br />

                                                                <strong>Address: </strong>{customer?.Nominee1_Address}
                                                            </p>

                                                        </div>

                                                        {/* Signature Section */}
                                                        <div className="col-md-4 d-flex flex-column align-items-center">
                                                            <div className="border" style={{ width: '100px', height: '120px' }}></div>
                                                            <p className="mt-2 mb-4 fw-bold">Signature</p>
                                                            <p className="mb-2">(Date: &nbsp;&nbsp;&nbsp;&nbsp;/ &nbsp;&nbsp;&nbsp;&nbsp;/  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</p>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        {/* Borrower Details */}
                                                        <div className="col-md-8">
                                                            <p style={{ padding: "7px", marginBottom: 0 }}><strong>Witness Signature:</strong><br />
                                                                <strong>Witness of Execution of Agreement</strong><br />
                                                                <strong>Name: </strong>{customer?.Declaration_Witness_PreName} {customer?.Declaration_Witness_Name}<br />
                                                                <strong>Address: </strong>{customer?.Declaration_Witness_Address}
                                                            </p>

                                                        </div>

                                                        {/* Signature Section */}
                                                        <div className="col-md-4 d-flex flex-column align-items-center">
                                                            <div className="border" style={{ width: '100px', height: '120px' }}></div>
                                                            <p className="mt-2 mb-4 fw-bold">Signature</p>
                                                            <p className="mb-2">(Date: &nbsp;&nbsp;&nbsp;&nbsp;/ &nbsp;&nbsp;&nbsp;&nbsp;/  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</p>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        {/* Borrower Details */}
                                                        <div className="col-md-8">
                                                            <p style={{ padding: "7px", marginBottom: 0 }}><strong>Witness of Execution of Agreement:</strong><br />
                                                                <strong>Name: </strong>Mrs. Ujwala Parshuram Thul<br />
                                                                <strong>Address: </strong>Flat no.506, C Wing,
                                                                Sarvoday Pooja Chs, Near Ayush Hospital,
                                                                Khadakpada, Kalyan (W) 421301
                                                            </p>

                                                        </div>

                                                        {/* Signature Section */}
                                                        <div className="col-md-4 d-flex flex-column align-items-center">
                                                            <div className="border" style={{ width: '100px', height: '120px' }}></div>
                                                            <p className="mt-2 mb-2 fw-bold">Signature</p>
                                                            <p className="mb-0">(Date: &nbsp;&nbsp;&nbsp;&nbsp;/ &nbsp;&nbsp;&nbsp;&nbsp;/  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    ) : (<p>Loadinggg...</p>)}
                                </div>
                            </div>
                            <div className={userData?.role === 'Admin' ? 'd-none' : "row justify-content-center mt-3"}>
                            {/* <div className="row justify-content-center mt-3"> */}
                                <div className="col-md-2">
                                    <button type="submit" className="btn btn-primary mb-1"
                                        onClick={showSummarry}
                                    >
                                        Edit
                                    </button>&nbsp;&nbsp;
                                    <button type="submit" className="btn btn-success mb-1"
                                    onClick={save}
                                    >
                                        Verify
                                    </button>
                                </div>

                            </div>
                            {loader}

                        </div>
                    </div>

                </div>
                {IsPreview && (
                    <UpdateLoanAgreement
                        open={IsPreview}
                        close={closed}
                        Id={id}
                    />
                )}
                {loader}
            </div>
        </div>
    );
};
export default Verification;
