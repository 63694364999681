import * as React from "react";
import * as XLSX from "xlsx";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import { useUser } from "../../componets/UserContext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { mdiFileExcel } from "@mdi/js";
import { apiUrl } from "../../componets/apiConfig";
import useFullPageLoader from "../../mui/useFullPageLoader";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
// import User from "./UserPreview";
import DownloadIcon from "@mui/icons-material/Download";
import AgreementPreview from "./AgreementPreview"
import PrintIcon from '@mui/icons-material/Print';
const ViewFreshAgreement = () => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [data, setStaffData] = useState([]);
    const navigate = useNavigate();
    const [dataa, setStaffDataa] = useState([]);
    const [customer, setIndexData] = useState({});
    const [isModal, setIsModal] = useState(false);
    const [userDetail, setUserDetails] = useState();
    const [filter, setFilter] = useState({
        Type: "",
        FromDate: "",
        ToDate: "",
    });

    const showSummary = (row) => {
        setIndexData(row);
        setIsModal(true);

    };

    const closed = (status) => {
        setIsModal(false);
        loadStaffData();
    };

    useEffect(() => {
        if (userData && userData.Org_Id) {
            loadStaffData();
        }
    }, [userData, filter]);

    const FilterClick = () => {
        loadStaffData();
    };


    const loadStaffData = async () => {
        showLoader();
        try {
            const result = await axios.get(
                `${apiUrl}/getLoanAgreement?Type=${filter?.Type}&fromDate=${filter?.FromDate}&toDate=${filter?.ToDate}`
            );
            setStaffData(result.data || []);
            setStaffDataa(result.data || []);
            console.log(result?.data || []);

            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching data: ", error);
        }
    };

    const deleteUser = (EmpId, name) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.value) {
                handleDelete(EmpId, name);
            }
        });
    };

    const handleDelete = async (id, name) => {
        const obj = {
            User_Role: userData.role,
            Emp_Id: userData.Employee_Id,
            Added_By_Id: userData.userId,
            Added_By: `${userData.firstName} ${userData.lastName}`,
            Org_Id: userData.Org_Id,
            UserName: userData.UserName,
            Name: name
        }
        await axios.delete(`${apiUrl}/deleteLoanAgreement/${id}`, { data: obj });
        Swal.fire("Deleted!", "Your record has been Deleted.", "success");
        loadStaffData();
    };

    const columns = [
        {
            name: "Sr No",
            selector: (row, index) => index+1,
            sortable: true,
            width:"90px"
        },
        {
            name: "Agreement Date",
            selector: (row) => formatDate(row.Date),
            sortable: true,
        },
        {
            name: "Customer Name",
            cell: (row) => (
                <>
                    {userData?.role === "DOP" && row.Verified_DOP === "" ? (
                        <Link to={`/Verification/${row?.id}`}>
                            {`${row.Lender_PreName} ${row.Lender_Name}`}
                        </Link>
                    ) : userData?.role === "Checker" && row.Verified_DOP === "Yes" && row.Verified_Checker === "" ? (
                        <Link to={`/Verification/${row?.id}`}>
                            {`${row.Lender_PreName} ${row.Lender_Name}`}
                        </Link>
                    ) : userData?.role === "Issuer" && row.Verified_DOP === "Yes" && row.Verified_Checker === "Yes" && row.Verified_Issuer === "" ? (
                        <Link to={`/Verification/${row?.id}`}>
                            {`${row.Lender_PreName} ${row.Lender_Name}`}
                        </Link>
                    ) : (
                        <>{`${row.Lender_PreName} ${row.Lender_Name}`}</>
                    )}
                </>
            ),
            sortable: true,
        },
        {
            name: "Customer Mobile",
            selector: (row) => row.Lender_Registered_Mobile_No,
            width: "120px",
        },
        {
            name: "Customer Email",
            cell: (row) => (
                <LightTooltip title={row.Lender_Email_ID}>
                    <span
                        style={{
                            // maxWidth: "90px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            padding: "5px",
                            cursor: "pointer",
                        }}
                    >
                        {row.Lender_Email_ID ? row.Lender_Email_ID : "--"}
                    </span>
                </LightTooltip>
            ),
        },
        {
            name: "Address",
            cell: (row) => (
                <LightTooltip title={row.Lender_Address}>
                    <span
                        style={{
                            maxWidth: "70px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            padding: "5px",
                            cursor: "pointer",
                        }}
                    >
                        {row.Lender_Address ? row.Lender_Address : "--"}
                    </span>
                </LightTooltip>
            ),
        },
        // {
        //     name: "Total Loan Amount",
        //     selector: (row) => parseFloat(row.Total_Loan_Amount).toFixed(2),
        // },
        {
          name: "Verified",
          cell: (row) => (
          <div>
                  {row.Verified_DOP === "" ? (
            <i className="far fa-circle"></i>
          ) : (
            <i className="fas fa-check-circle text-success" ></i>
          )} &nbsp;
                  {row.Verified_Checker === "" ? (
            <i className="far fa-circle"></i>
          ) : (
            <i className="fas fa-check-circle text-success"></i>
          )} &nbsp;
                  {row.Verified_Issuer === "" ? (
            <i className="far fa-circle"></i>
          ) : (
            <i className="fas fa-check-circle text-success"></i>
          )}
          </div>
            ),
            width: "90px"

        },
        {
            name: "Type",
            cell: (row) => (
                <a
                    id="punchStatus"
                    style={{ cursor: "pointer" }}
                    className={`badge rounded-pill ${row?.Agreement_Type === "Fresh"
                        ? "badge badge-success text-white"
                        
                        : "badge badge-warning text-white"
                        } rounded-pill`}
                >
                    {row?.Agreement_Type}
                </a>
            ),
            width: "90px"
        },
        {
            name: "",
            cell: (row) => (
                <a
                    className={row.Agreement_Doc === "" ? "d-none" : "cursor-pointer"}
                    style={{ color: "blue" }}
                    href={`${apiUrl}/AgreementDoc/${row.Agreement_Doc}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <DownloadIcon fontSize="medium" />
                </a>
            ),
            width: "45px",
        },
        {
            name: "",
            cell: (row) => (
                <div className="dropdown">
                    <a
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <i>
                            <MoreVertIcon
                                style={{
                                    color: "grey",
                                    fontSize: "18px",
                                    cursor: "pointer",
                                }}
                            />
                        </i>
                    </a>
                    <div className="dropdown-menu">
                        {row?.Verified_Issuer &&( <Link className="dropdown-item" onClick={()=>showSummary(row)}>
                            <PrintIcon className="text-info" style={{ fontSize: "18px" }} />{" "}
                            Print
                        </Link>)}
                        <Link className="dropdown-item" to={`/FreshAgreementUpdate/${row?.id}`}>
                            <EditIcon className="text-primary" style={{ fontSize: "18px" }} />{" "}
                            Edit
                        </Link>
                       
                        <Link className="dropdown-item" onClick={() => deleteUser(row?.id, `${row?.Lender1_PreName} ${row?.Lender1_Name}`)}>
                                <DeleteIcon className="text-danger" style={{ fontSize: "18px" }} />{" "}
                                Delete
                            </Link>
                    </div>
                </div>
            ),
            width: "45px",
        },

    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: "30px",
            },
        },
    };

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: "rgba(0, 0, 0, 0.87)",
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }));

    const DownloadPDF = () => {
        const doc = new jsPDF({
            orientation: 'landscape'
        });
        doc.text("Employee Details", 20, 10);

        const columns = [
            "Emp ID",
            "Joining Date",
            "Name",
            "Mobile",
            "Email",
            "Role",
            "Address",
        ];

        const rows = data.map((row) => [
            row.Employee_Id,
            formatDate(row.Joining_Date),
            `${row.First_Name} ${row.Last_Name}`,
            row.Mobile,
            row.Email ? row.Email : "--",
            row.Role,
            row.Address ? row.Address : "--",
        ]);

        doc.autoTable({
            head: [columns],
            body: rows,
        });

        doc.save("Employee Details.pdf");
    };

const DownloadExcel = () => {
    const columns = [
        "Emp ID",
        "Joining Date",
        "Name",
        "Mobile",
        "Email",
        "Role",
        "Address",
        "Date",
        "Location",
        "Lender PreName",
        "Lender Name",
        "Lender1 PreName",
        "Lender1 Name",
        "Lender2 PreName",
        "Lender2 Name",
        "Lender Address",
        "Borrower PreName",
        "Borrower Name",
        "Borrower Address",
        "Loan Amount (Text)",
        "Loan Amount (Numeric)",
        "Loan Start Date",
        "Loan End Date",
        "Mode of Payment",
        "Loan Disbursal Date",
        "Transaction ID",
        "Bank",
        "Total Loan Amount",
        "Received Date",
        "Tenure",
        "KYC Date",
        "KYC Time",
        "Lender Mobile No",
        "Lender Registered Mobile No",
        "Lender Email ID",
        "Borrower Email ID",
        "Lock-In Period From Date",
        "Lock-In Period To Date",
        "PayIn Bank Account Holder PreName",
        "PayIn Bank Account Holder Name",
        "PayIn Bank Name",
        "PayIn Account Number",
        "PayIn IFSC Code",
        "PayIn Branch",
        "PayIn Account Holder Type",
        "PayIn Account Type",
        "Payout Bank Account Holder PreName",
        "Payout Bank Account Holder Name",
        "Payout Bank Name",
        "Payout Account Number",
        "Payout IFSC Code",
        "Payout Branch",
        "Payout Account Holder Type",
        "Payout Account Type",
        "Nominee1 PreName",
        "Nominee1 Name",
        "Nominee1 Address",
        "Nomination Gender",
        "Nominee Loan Amount",
        "Nomination Date",
        "Nominee DOB",
        "Nominee Relation",
        "Declaration Lender Name",
        "Declaration Lender Address",
        "Declaration Loan Amount (Numeric)",
        "Declaration Loan Amount (Words)",
        "Declaration Nominee Name",
        "Declaration Nominee Address",
        "Declaration Witness PreName",
        "Declaration Witness Name",
        "Declaration Witness Address",
        "Borrower Mobile",
        "Agreement Type",
        "Bank Details",
        "Payment Proof",
        "Aadhar Card",
        "Pan Card",
        "Attachment1",
        "Attachment2",
        "Added By",
        "Added By Name",
        "Added By Role",
        "Added On",
        "Verified Checker",
        "Verified Issuer",
        "OTP"
    ];

    const rows = [columns];
    data.forEach((row) => {
        rows.push([
            row.Employee_Id,
            formatDate(row.Joining_Date),
            `${row.First_Name} ${row.Last_Name}`,
            row.Mobile,
            row.Email ? row.Email : "--",
            row.Role,
            row.Address ? row.Address : "--",
            formatDate(row.Date),
            row.Location,
            row.Lender_PreName,
            row.Lender_Name,
            row.Lender1_PreName||"",
            row.Lender1_Name||"",
            row.Lender2_PreName||"",
            row.Lender2_Name||"",
            row.Lender_Address,
            row.Borrower_PreName,
            row.Borrower_Name,
            row.Borrower_Address,
            row.Loan_Amount_txt,
            row.Loan_Amount_num,
            formatDate(row.Loan_Start_Date),
            formatDate(row.Loan_End_Date),
            row.Mode_of_Payment,
            formatDate(row.Loan_Disbursal_Date),
            row.Transaction_ID,
            row.Bank,
            row.Total_Loan_Amount,
            formatDate(row.Received_Date),
            row.Tenure,
            formatDate(row.KYC_Date),
            row.KYC_Time,
            row.Lender_Mobile_No,
            row.Lender_Registered_Mobile_No,
            row.Lender_Email_ID,
            row.Borrower_Email_ID,
            formatDate(row.LockIn_Period_From_Date),
            formatDate(row.LockIn_Period_To_Date),
            row.PayIn_Bank_Account_Holder_PreName,
            row.PayIn_Bank_Account_Holder_Name,
            row.PayIn_Bank_Name,
            row.PayIn_Account_Number,
            row.PayIn_IFSC_Code,
            row.PayIn_Branch,
            row.PayIn_Account_Holder_Type,
            row.PayIn_Account_Type,
            row.Payout_Bank_Account_Holder_PreName,
            row.Payout_Bank_Account_Holder_Name,
            row.Payout_Bank_Name,
            row.Payout_Account_Number,
            row.Payout_IFSC_Code,
            row.Payout_Branch,
            row.Payout_Account_Holder_Type,
            row.Payout_Account_Type,
            row.Nominee1_PreName,
            row.Nominee1_Name,
            row.Nominee1_Address,
            row.Nomination_Gender,
            row.Nominee_Loan_Amount,
            formatDate(row.Nomination_Date),
            formatDate(row.Nominee_DOB),
            row.Nominee_Relation,
            row.Declaration_Lender_Name,
            row.Declaration_Lender_Address,
            row.Declaration_Loan_Amount_Num,
            row.Declaration_Loan_Amount_Words,
            row.Declaration_Nominee_Name,
            row.Declaration_Nominee_Address,
            row.Declaration_Witness_PreName,
            row.Declaration_Witness_Name,
            row.Declaration_Witness_Address,
            row.Borrower_Mobile,
            row.Agreement_Type ? row.Agreement_Type : "--",
            row.Bank_Details ? row.Bank_Details : "--",
            row.Payment_Proof ? row.Payment_Proof : "--",
            row.Aadhar_Card ? row.Aadhar_Card : "--",
            row.Pan_Card ? row.Pan_Card : "--",
            row.Attachment1 ? row.Attachment1 : "--",
            row.Attachment2 ? row.Attachment2 : "--",
            row.Added_By ? row.Added_By : "--",
            row.Added_By_Name ? row.Added_By_Name : "--",
            row.Added_By_Role ? row.Added_By_Role : "--",
            formatDate(row.Added_On),
            row.Verified_Checker ? row.Verified_Checker : "--",
            row.Verified_Issuer ? row.Verified_Issuer : "--",
            row.OTP ? row.OTP : "--"
        ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Agreement Details");

    // Download the file
    XLSX.writeFile(workBook, "Agreement Details.xlsx");
};

    function handleFilter(event) {
        const filterValue = event.target.value.toLowerCase();
        const filteredData = dataa.filter((row) => {
            return (
                (row.Lender_Name &&
                    row.Lender_Name
                        .toLowerCase()
                        .includes(filterValue)) ||
                (row.Lender_Mobile_No && row.Lender_Mobile_No.toLowerCase().includes(filterValue)) ||
                (row.Lender_Email_ID && row.Lender_Email_ID.toLowerCase().includes(filterValue)) ||
                (row.Total_Loan_Amount && row.Total_Loan_Amount.toLowerCase().includes(filterValue)) ||
                (row.Lender_Address && row.Lender_Address.toLowerCase().includes(filterValue)) ||
                (row.Agreement_Type &&
                    row.Agreement_Type.toLowerCase().includes(filterValue)) ||
                (row?.Date &&
                    formatDate(row?.Date).toLowerCase().includes(filterValue))
            );
        });
        setStaffData(filteredData);
    }

    const formatDate = (dateString) => {
        const options = { day: "2-digit", month: "2-digit", year: "numeric" };
        return new Date(dateString).toLocaleDateString("en-GB", options);
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column mb-2">
                        <h5 className="page-title">View Agreement</h5>
                        <Link to={'/Fresh_Agreement'} className="btn btn-primary ml-md-3 mt-md-0 d-none">Add</Link>

                    </div>

                    <div className="card mb-2">
                        <div className="card-body">
                            <div className="row justify-content-center mt-2">
                                <div className="col-4">
                                    <label htmlFor="example-select" className="form-label">
                                        Type
                                    </label>
                                    <select
                                        className="form-control"
                                        id="example-select"
                                        onChange={(e) => {
                                            setFilter({
                                                ...filter,
                                                Type: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value={""}>All</option>
                                        <option value={"Fresh"}>Fresh</option>
                                        <option value={"Renewal"}>Renewal</option>

                                    </select>
                                </div>
                                <div className="col-4">
                                    <label htmlFor="example-select" className="form-label">
                                        From Date
                                    </label>
                                    <input
                                        type="date"
                                        id="txFromtDate"
                                        name="txFromtDate"
                                        className="form-control"
                                        //placeholder="Date"
                                        dateFormat="dd-MM-yyyy"
                                        onChange={(e) => {
                                            setFilter({
                                                ...filter,
                                                FromDate: e.target.value,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="example-select" className="form-label">
                                        To Date
                                    </label>
                                    <input
                                        type="date"
                                        id="txtToDate"
                                        name="txtToDate"
                                        className="form-control"
                                        //placeholder="Date"
                                        dateFormat="dd-MM-yyyy"
                                        onChange={(e) => {
                                            setFilter({
                                                ...filter,
                                                ToDate: e.target.value,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row justify-content-center mt-2">
                                <div className="col-2">
                                    <button
                                        className="btn btn-primary"
                                        onClick={FilterClick}
                                        style={{ width: "100%" }}
                                    >
                                        {" "}
                                        Filter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <Stack
                                direction="row"
                                spacing={2}
                                className="mx-2 my-2 mb-2"
                                sx={{ zIndex: 0, position: "relative" }}
                            >
                                <TextField
                                    onChange={handleFilter}
                                    size="small"
                                    label="Search..."
                                />
                                <div>
                                    <Tooltip className="d-none" title="Export to PDF">
                                        <i
                                            style={{
                                                cursor: "pointer",
                                                padding: "5px",
                                            }}
                                            onClick={() => {
                                                DownloadPDF();
                                            }}
                                        >
                                            <PictureAsPdfIcon />
                                        </i>
                                    </Tooltip>

                                    <Tooltip title="Export to Excel">
                                        <i
                                            style={{
                                                padding: "5px",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                DownloadExcel();
                                            }}
                                        >
                                            {" "}
                                            <Icon path={mdiFileExcel} size={1} />
                                        </i>
                                    </Tooltip>
                                </div>
                            </Stack>
                            <div style={{ zIndex: 0, position: "relative" }}>
                                <DataTable
                                    // title="Employee Data"
                                    columns={columns}
                                    data={data}
                                    pagination
                                    highlightOnHover
                                    customStyles={customStyles}
                                    fixedHeader
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {isModal && (
                     <AgreementPreview
                            open={isModal}
                            close={(status) => closed(status)}
                            customer={customer}
                            status={"1"}
                        />
                )}
                {loader}
            </div>
        </div>
    );
};
export default ViewFreshAgreement;
